:root {
    --font-family-1: "Roboto", "Helvetica", "sans-serif";
    --font-family-2: "Oswald", "Helvetica", "sans-serif";
    --font-size-default: 22px;
    --font-size-small: 14px;
    --unit: 16px;

    --color-primary: #483f38;
    --color-accent: #2b685f;
    --color-background: #f9f8f6;
}

body {
    background-color: var(--color-background);
    color: var(--color-primary);
    padding: 16px;
    font-family: var(--font-family-1);
    font-size: var(--font-size-default);
}

.App {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 400px));
    gap: calc(var(--unit) * 2);
}

header {
    grid-column: 1 / -1;
    margin: 0;
    text-transform: uppercase;
    font-size: var(--font-size-default);
}
header h1 {
    margin: 0;
}

.card {
    /* background-color: red; */
    /* border-left: 1px solid var(--color-primary); */
    /* padding-left: var(--unit); */
}

.cardValue {
    color: var(--color-accent);
    font-size: calc(3 * var(--font-size-default));
    text-transform: uppercase;
}

.card h1 {
    /* margin: 0 0 calc(var(--unit) * 2) 0; */
    border-left: 1px solid var(--color-primary);
    padding-left: var(--unit);
    box-sizing: border-box;
    transform: rotate(-10deg);
    /* text-align: center; */
}

.cardUnit {
    font-size: 0.5em;
    font-weight: normal;
    margin-left: var(--unit);
}

.card label {
    text-transform: uppercase;
    font-family: var(--font-family-2);
    text-align: right;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-primary);
    margin-bottom: var(--unit);
}

.card p {
    line-height: 1.5em;
    margin: 0;
}

footer {
    border-top: 1px solid var(--color-primary);
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: var(--font-size-small);
}

footer h1 {
    grid-column: 1 / -1;
    font-size: var(--font-size-small);
    text-transform: uppercase;
    font-weight: normal;
}
